
<style scoped>
  /* @import '~bootstrap/dist/css/bootstrap.css'; */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import '~vue-toast-notification/dist/theme-sugar.css';
  @import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
</style>

<template id="main-app">
  <div class="page-wrapper chiller-theme toggled">
    <a
      id="show-sidebar"
      class="btn btn-sm btn-light text-left"
      href="javascript:void(0)"
      style="border-radius: 0px; color: brown"
    >
      <i class="fas fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <a href="#">
            <img
              class="img-responsive"
              src="../public/assets/img/logo-compta-red.png"
              alt="Logo"
              style="max-width: 100%; max-height: 50px"
            />
          </a>
          <div id="close-sidebar">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="sidebar-header" style="margin-top: 70px">
          <div class="user-pic">
            <img
              class="img-responsive img-rounded"
              src="../public/assets/img/user.jpg"
              alt="User picture"
            />
          </div>
          <div class="user-info">
            <span class="user-name">
              <strong>{{nom_users}}</strong>
            </span>
            <span class="user-role">{{role_user}}</span>
            <span class="user-status">
              <i class="fa fa-caret-right"></i>
              <span
                ><a @click="doLogout" href="javascript:void(0)" style="color: #888;">
                  Se deconnecter</a
                ></span
              >
            </span>
          </div>
        </div>

        <div class="sidebar-menu">
          <ul id="nav">
            
            <li class="dropdown" v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-users"></i> Tableau de bord
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/dashboard">
                    <i class="fas fa-angle-right"></i>
                    <span>Chiffre d'affaire</span>
                  </router-link>
                </li>
              </ul>
            </li>
            
            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-file-signature"></i> Ecritures
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/ecritures">
                    <i class="fas fa-angle-right"></i>
                    <span>Saisie des écritures</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/brouillard">
                    <i class="fas fa-angle-right"></i>
                    <span>Brouillard</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/journal">
                    <i class="fas fa-angle-right"></i>
                    <span>Journal</span>
                  </router-link>
                </li>
              </ul>
            </li>
            
            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-chart-bar"></i> Edition
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li class="dropdown">
                  <a href="javascript:void(0)" class="menu-group">
                    <i class="fa fa-bookmark"></i> Grand livre
                    <span class="indice">
                      <i class="fa fa-angle-up"></i>
                    </span>
                  </a>
                  <ul class="open">
                    <li>
                      <router-link to="/grandlivre">
                        <i class="fas fa-caret-right"></i>
                        <span>Grand livre général</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/grandlivreaux">
                        <i class="fas fa-caret-right"></i>
                        <span>Grand livre auxiliaire</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0)" class="menu-group">
                    <i class="fa fa-chart-pie"></i> Balance
                    <span class="indice">
                      <i class="fa fa-angle-up"></i>
                    </span>
                  </a>
                  <ul class="open">
                    <li>
                      <router-link to="/balance">
                        <i class="fas fa-angle-right"></i>
                        <span>Balance général</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/balanceaux">
                        <i class="fas fa-angle-right"></i>
                        <span>Balance auxiliaire</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                
                
                <li v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
                  <router-link to="/cptresultat">
                    <i class="fas fa-angle-right"></i>
                    <span>Compte de résultat</span>
                  </router-link>
                </li>
                <!-- <li v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
                  <router-link to="/">
                    <i class="fas fa-angle-right"></i>
                    <span>CR par nature</span>
                  </router-link>
                </li>
                <li v-if="role_user=='Super admin' || role_user=='dévéloppeur'">
                  <router-link to="/">
                    <i class="fas fa-angle-right"></i>
                    <span>CR par fonction</span>
                  </router-link>
                </li> -->
            
                <li v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
                  <router-link to="/">
                    <i class="fas fa-angle-right"></i>
                    <span>Bilan</span>
                  </router-link>
                </li>
                <li v-if="role_user=='Super admin' || role_user=='dévéloppeur'">
                  <router-link to="/">
                    <i class="fas fa-angle-right"></i>
                    <span>Annexes</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-clipboard-list"></i> Plan comptable standard
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li>
                  <router-link to="/plancomptable">
                    <i class="fas fa-angle-right"></i>
                    <span>Plan comptable</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/tiersetaux">
                    <i class="fas fa-angle-right"></i>
                    <span>Compte tiers / Auxiliaires</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/journauxdesaisie">
                    <i class="fas fa-angle-right"></i>
                    <span>Journaux de saisie</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-cogs"></i> Paramètres
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
                  <router-link to="/tbsetting">
                    <i class="fas fa-angle-right"></i>
                    <span>Parametre de l'application</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/infosociete">
                    <i class="fas fa-angle-right"></i>
                    <span>Information sur l'entreprise</span>
                  </router-link>
                </li>
                <li v-if="role_user=='Super admin' || role_user=='dévéloppeur'">
                  <router-link to="/exercices">
                    <i class="fas fa-angle-right"></i>
                    <span>Exercices</span>
                  </router-link>
                </li>
                <li v-if="role_user=='Super admin' || role_user=='dévéloppeur'">
                  <router-link to="/">
                    <i class="fas fa-angle-right"></i>
                    <span>Cloture de l'exercice</span>
                  </router-link>
                </li>
              </ul>
            </li>
            
            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-users-cog"></i> Gestion des utilisateurs
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li v-if="role_user == 'Comptable'">
                  <router-link to="/users" v-if="role_user=='Comptable'">
                    <i class="fas fa-angle-right"></i>
                    <span>Mon profil</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/users" v-if="role_user=='Super admin' || role_user=='Administrateur' || role_user=='dévéloppeur'">
                    <i class="fas fa-angle-right"></i>
                    <span>Liste des utilisateurs</span>
                  </router-link>
                </li>
              </ul>
            </li>
            
            <!-- <li>
              <router-link to="/">
                <i class="fas fa-angle-right"></i>
                <span>Droit d'accès</span>
              </router-link>
            </li> -->
            <!-- <li>
              <router-link to="/">
                <i class="fas fa-angle-right"></i>
                <span>Historique de connexions</span>
              </router-link>
            </li> -->
            
            <li class="dropdown">
              <a href="javascript:void(0)" class="menu-group">
                <i class="fa fa-database"></i> Base de données
                <span class="indice">
                  <i class="fa fa-angle-up"></i>
                </span>
              </a>
              <ul class="open">
                <li v-if="role_user=='Super admin' || role_user=='dévéloppeur'">
                  <a href="database/backup" target="_blank">
                    <i class="fa fa-angle-right"></i>
                    <span>Sauvegarder la bdd</span>
                  </a>
                </li>
              </ul>
            </li>

          </ul>
        </div>
        <!-- sidebar-menu  -->
      </div>
      <!-- sidebar-content  -->
    </nav>

    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="all-content">
        <header>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">
              <span @click="openExerciceModal">
                <i class="fas fa-store-alt"></i>
                Exercice <span class="text-bold">{{ current_exercice }}</span> &nbsp;
              </span>
              
              <!-- <i class="fas fa-store-alt"></i>
               STIMEX<i class="fas fa-angle-right"></i>
              <strong> 2021</strong> &nbsp; -->
              &nbsp;<i
                class="fas fa-angle-right"
              ></i>
              <span> {{ titre }}</span>
            </a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav mr-auto"></ul>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="btn btn-light" type="button" title="Saisi des écritures" >
                    <i class="fas fa-edit fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="btn btn-light" type="button" title="Brouillard" >
                    <i class="fas fa-pencil-ruler fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="btn btn-light" type="button" title="Plan comptable" >
                    <i class="fas fa-book fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Paramètres utilisateurs"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-users-cog fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a class="dropdown-item" href="#"
                      ><i class="fas fa-user"></i> Mon profil</a
                    >
                    <!-- <a class="dropdown-item" href="#"
                      ><i class="fas fa-users-cog"></i> Groupe d'utilisateur</a
                    >
                    <a class="dropdown-item" href="#"
                      ><i class="fas fa-users"></i> Utilisateur du logiciel</a
                    > -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="doLogout()"
                      ><i class="fas fa-sign-out-alt"></i> Se déconnecter</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div class="container-fluid">
          <router-view @change-page="onChangePage" />
        </div>
        <div v-if="showLoading" class="dna-loader">
          <img src="/public/assets/img/loading.gif">
        </div>
      </div>
    </main>
    <!-- page-content" -->

    
    <b-modal
      id="exerciceModal"
      title="Changer d'exercice"
      size="lg"
      hide-footer
    >
      <div class="row">

        <div class="col-md-6" v-for="o in exercices" :key="o.id">
          <button type="button" :class="'btn btn-block py-4 mb-4 ' + (current_exercice == o.annee_exercice ? 'btn-secondary' : 'btn-light')" @click="choisirExercice(o.id)" style="border: solid 1px #CCC">
            <b><h4 class="mb-2">{{ o.annee_exercice }}</h4></b>
            <h6 class="mb-1">{{ o.description }}</h6>
            <small><b>{{o.date_debut_formatted}}</b> au <b>{{o.date_fin_formatted}}</b></small>
          </button>
        </div>
      </div>
    </b-modal>

  </div>
</template>
  
<script>

import Vue from 'vue';
import $ from 'jquery';
import axios from "axios";
export default {
  props: {
    users_info: { 
      type: Object,
      default: function() {
        return [];
      },
      required: false
    },

  },
  data: function () {
    return {
      titre: "",
      showLoading: false,
      nom_user: "DNA Webhosting",
      role_user: "",
      base_url: Vue.BASE_URL,
      current_exercice: "",
      exercices: [],
    };
  },
  methods: {
    onChangePage: function (pageTitle) {
      this.titre = pageTitle;
      console.log(this.titre);
    },
    onChangeLoad: function (status, userData) {
      //console.log("LOADING");
      console.log("-----------");
      // console.log(userData);
      console.log("-----------");
      this.nom_user = userData.users_compta_nom;
      this.role_user = userData.users_compta_role;
      this.current_exercice = userData.users_compta_exercice;
    },
    doLogout: function () {
      var that = this;
      axios.get(this.base_url+"/users/logout").then(function () {
        that.$router.push("/login");
      });
    },
    openExerciceModal() {
      var that = this;
      axios.get(this.BASE_URL + "/exercice/getall").then(function (response) {
        that.exercices = response.data;
      });
      this.$bvModal.show("exerciceModal");
    },
    closeExerciceModal() {
      this.$bvModal.hide("exerciceModal");
    },
    choisirExercice($id_exercice) {
      var that = this;
      axios
        .get(this.BASE_URL + "/exercice/choisirexercice/" + $id_exercice)
        .then(function (response) {
          that.current_exercice = response.data.users_compta_exercice;
          that.closeExerciceModal();
          location.reload();
        });
    },
    loadscript() {

      $("#close-sidebar").click(function() {
          $(".page-wrapper").removeClass("toggled");
      });
      $("#show-sidebar").click(function() {
          $(".page-wrapper").addClass("toggled");
      });

      
      
      $('li.dropdown a.menu-group').click(
        function(el){
            $(el).parent('li').find('ul').slideDown();
        },
        function(el){
            if($(el).parent('li').find('ul').hasClass('open')) {
              $(el).parent('li').find('ul').slideUp();
              $(el).parent('li').find('ul').removeClass('open');
              $(el).parent('li').find('.indice').html('<i class="fa fa-angle-down"></i>');

            }
            else {
              $(el).parent('li').find('ul').addClass('open');
              $(el).parent('li').find('ul').slideDown();
              $(el).parent('li').find('.indice').html('<i class="fa fa-angle-up"></i>');
            }
        }
      );

    }
  },
  created: function () {
    this.$on("change-load", this.onChangeLoad);
    // console.log(this.users_info);
    
    
    this.nom_user = this.users_info.users_compta_nom;
    this.role_user = this.users_info.users_compta_role;
    this.current_exercice = this.users_info.users_compta_exercice;
  },
  mounted: function() {
    this.loadscript();

  },
  computed: {
    nom_users() {
      if (this.nom_user == "" || this.nom_user == null) {
        return "";
      }
      return this.nom_user.replaceAll("+", " ");
    },
  },
};
</script>

