import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/dashboard/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import( '../views/Dashboard/Dashboard.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import( '../views/Dashboard/Dashboard.vue')
  },
  {
    path: '/plancomptable',
    name: 'PlanComptable',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlanComptable/PlanComptable.vue')
  },
  {
    path: '/ecritures',
    name: 'Ecritures',
    component: () => import( '../views/Ecritures/Ecritures.vue')
  },
  {
    path: '/ecritures/:idecriture',
    name: 'EcrituresEdit',
    component: () => import( '../views/Ecritures/Ecritures.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import( '../views/Articles/Articles.vue')
  },
  {
    path: '/brouillard',
    name: 'Brouillard',
    component: () => import( '../views/Brouillard/Brouillard.vue')
  },
  {
    path: '/journal',
    name: 'Journal',
    component: () => import( '../views/Journal/Journal.vue')
  },
  {
    path: '/journauxdesaisie',
    name: 'JournauxDeSaisie',
    component: () => import( '../views/JournauxDeSaisie/JournauxDeSaisie.vue')
  },
  {
    path: '/tiersetaux',
    name: 'CompteTiersAuxiliaires',
    component: () => import( '../views/CompteTiersAuxiliaires/CompteTiersAuxiliaires.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import( '../views/Users/Users.vue')
  },
  {
    path: '/grandlivre',
    name: 'GrandLivre',
    component: () => import( '../views/GrandLivre/GrandLivre.vue')
  },
  {
      path: '/infosociete',
      name: 'Infosociete',
      component: () =>
          import ('../views/Infosociete/Infosociete.vue')
  },
  {
      path: '/balance',
      name: 'Balance',
      component: () =>
          import ('../views/Balance/Balance.vue')
  },
  {
      path: '/balanceaux',
      name: 'BalanceAux',
      component: () =>
          import ('../views/BalanceAux/BalanceAux.vue')
  },
  {
      path: '/grandlivreaux',
      name: 'GrandLivreAux',
      component: () =>
          import ('../views/GrandLivreAux/GrandLivreAux.vue')
  },
  {
      path: '/tbsetting',
      name: 'ParametreDashboard',
      component: () =>
          import ('../views/ParametreDashboard/ParametreDashboard.vue')
  },
  {
      path: '/cptresultat',
      name: 'CompteDeResultat',
      component: () =>
          import ('../views/CompteDeResultat/CompteDeResultat.vue')
  },
  {
      path: '/exercices',
      name: 'Exercices',
      component: () =>
          import ('../views/Exercices/Exercices.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
